  
const initialState = {
	searchResult: [{
		id: 1,
		title: 'Emergency Page Name',
		date: '03/02/21 11:33 PM',
		icon: '',
		number: '923122331111',
		lastMessage: 'Last message long text here',
		count: 15,
		type: 'orange'
	},
	{
		id: 2,
		title: '03/02/21 - 11:33 PM',
		date: '03/02/21 11:33 PM',
		icon: '',
		number: '923122331111',
		lastMessage: 'last message',
		count: 2,
		type: 'green'
	},
	{
		id: 3,
		title: 'Very Long Title Lorem Ipsum Lorem ipsum',
		date: '03/02/21 11:33 PM',
		icon: '',
		number: undefined,
		lastMessage: 'last message',
		count: 12,
		type: 'blue'
	},
	{
		id: 4,
		title: 'Very Long Title Lorem Ipsum Lorem ipsum',
		date: '03/02/21 11:33 PM',
		icon: '',
		number: '923122331111',
		lastMessage: 'last message',
		count: 10,
		type: 'blue'
	}],
	selectedItem: {
		id: 1,
		title: 'Emergency Page Name',
		date: '03/02/21 11:33 PM',
		icon: '',
		number: '923122331111',
		lastMessage: 'Last message long text here',
		count: 15,
		type: 'orange'
	},
}

const getSearch = (state, action) => ({
	...state,
	searchLoading: true
})
const getSelectedSearchItem = (state, action) => ({
	...state,
	selectedItem: action.payload
})

const searchReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_SEARCH': return getSearch(state, action)
		case 'SET_SELECTED_ITEM': return getSelectedSearchItem(state, action)
		default: return state
	}
}

export default searchReducer
  