import {getApiV1 as v1Api} from "../api";
import {parseApiResponse} from '../apiError';
import URls from "../../../constants/urls";
import {LIVE_URL, DPS_URL} from "../../../constants/localStoreKeys";


export const getPage = async (pageId) => {
  return v1Api(LIVE_URL).get(`page/pageById/${pageId}`)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getSummary = async (data) => {
  return v1Api(DPS_URL).postJson(`dps/summary`, data)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getDistance = async (data) => {
  return v1Api(DPS_URL).postJson(`gps/distance`, data)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};
