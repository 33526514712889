import { all, fork } from 'redux-saga/effects'
import pageSagas from './pageSagas'
import {onMessageFromEventListenerSaga} from './WebSocketSagas'

export default function* rootSaga() {
  yield all([
    fork(pageSagas),
    fork(onMessageFromEventListenerSaga),

  ])
}
