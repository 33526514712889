import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import App from "containers/App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import './index.css';
import "react-toastify/dist/ReactToastify.css";
import browserHistory from "utils/history";
import initialState from "./store/initialState";
import { CookiesProvider } from "react-cookie";
import "./utils/polyfills/forEach";
import "./utils/polyfills/find";
import "./utils/polyfills/includes";
import "antd/dist/antd.css";

const store = configureStore(initialState);
export const action = (type, payload) => store.dispatch({ type, payload });

toast.configure();
ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      <BrowserRouter>
        <CookiesProvider>
          <Suspense fallback={"Loading..."}>
            <App />
          </Suspense>
        </CookiesProvider>
      </BrowserRouter>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about services workers: https://bit.ly/CRA-PWA
serviceWorker.register();
