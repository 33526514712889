import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import {getPage, getSummary, getDistance} from 'actions/Page';
import useSocket from 'use-socket.io-client';
import Geocode from "react-geocode";
import {DPS_SOCKET} from "../../constants/localStoreKeys";


const breakTime = [
    {time: 2, name: 'Event', icon: 'orange'},
    {time: 12, name: 'Place', icon: 'purple'},
    {time: 20, name: 'IoT', icon: 'yellow'},
    {time: 33, name: 'Capture', icon: 'gray'},
    {time: 41, name: 'Incident', icon: 'orange'},
]

const StartStopCancel = {
    Start: 'start',
    Stop: 'stop',
    Cancel: 'cancel',
}

const pageStatusMapping = {
    dispatched: {icon: 'blue'},
    closed: {icon: 'green'},
    escalated: {icon: 'purple'},
    arrived: {icon: 'red'},
    offline: {icon: 'gray'},
    started: {icon: 'green'},
    ended: {icon: 'red'},
    amber: {icon: 'yellow'},
    green: {icon: 'green'},
    red: {icon: 'red'},
}

const userStatusMapping = {
    enroute: {icon: 'blue'},
    online: {icon: 'green'},
    away: {icon: 'yellow'},
    busy: {icon: 'red'},
    offline: {icon: 'gray'},
    escalated: {icon: 'purple'}
}



const { TextArea } = Input;

const LocationItem = ({type, address, time}) =>
    <Row className="location-item d-flex">
        <Col>
            <Button className="beam-btn inbound icon-btn"><ReactSVG src="/images/geolocation-icon.svg" className={`${type}-icon`} /></Button>
        </Col>
        <Col span={20}>
            <h5>Source - {time || ''}</h5>
            <p>{address || 'N/A'}</p>
        </Col>
    </Row>

const PersonItem = ({member, avatar, status}) =>
    <Row className="person-item d-flex">
        <Col><img src={avatar} /></Col>
        <Col span={9}>
            <h5>{member.user?.beamId?.IdOne ?? ''}</h5>
            <p>ID: {member.user._id}</p>
        </Col>
        <Col><label className={`${userStatusMapping[status]?.icon ?? 'gray'}-icon`}>{status}</label></Col>
        <Col>
            <Button className="beam-btn icon-btn"><ReactSVG src="/images/flag-icon.svg" className={`${userStatusMapping[status].icon}-icon`} /></Button>
        </Col>
    </Row>


function AnalyticsSummaryModal(props) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [memberStatus, setMemberStatus] = useState({});
    const [pageStatus, setPageStatus] = useState([]);
    const [events, setEvents] = useState([]);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [startAddress, setStartAddress] = useState();
    const [endAddress, setEndAddress] = useState();
    const dispatch = useDispatch();
    const { pageLoading } = useSelector((state) => state.page);
    const { page } = useSelector((state) => state.page);
    const { summary } = useSelector((state) => state.page);
    const { distance } = useSelector((state) => state.page);
    // const [socket] = useSocket('https://first-gps.beam.live');
    const [socket] = useSocket(DPS_SOCKET);
    socket.connect();

    Geocode.setApiKey("AIzaSyBdiNxE31mGELQwmau05oOcld_1Kk2ED0s");
    Geocode.setLanguage("en");
    Geocode.enableDebug();

    const subscribeToDPSPage = (pageId) => {
        socket.emit('dps/subscribe-page', { pageId });
    }

    const unsubscribeToDPSPage = (pageId) => {
        socket.emit('dps/unsubscribe-page', { pageId });
    }


    const getLocalDatetimeString = (date) => {
        if(date.toString() == 'Invalid Date')return 'N/A';
        const dateObject = typeof date === 'string' ? new Date(date) : date;
        return dateObject
            ? `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`
            : 'N/A';
    }

    const getAddress = (lat, lng) => {
        return new Promise((resolve, reject) => {
            Geocode.fromLatLng(lat, lng).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    resolve(address)
                },
                (error) => {
                    reject(error)
                }
            );
        });
    }


    const getFirstEvent = () => {
        return (
            events.find((x)=>{return x.eventName === StartStopCancel.Start}) || {}
        );
    }

    const getLastEvent = () => {
        return (
            events.find((x)=>{return (x.eventName === StartStopCancel.Stop || x.eventName == StartStopCancel.Cancel)}) || {}
        );
    }

    const getDeviceImei = () => {
        return (
            events.find((x)=>{return (x.type === 'message' && x.data.type == 'gps')})?.userName ?? undefined
        );
    }


    useEffect(() => {
        if(page) {
            let memberStatus = {}
            page.members = page.members.filter((member, index, self) =>
                index === self.findIndex((t) => (
                    t.user._id === member.user._id
                ))
            );
            let memberIds = page.members.map((member) => {
                memberStatus[member.user._id] = member.user.status;
                return member.user._id
            });
            setMemberStatus(memberStatus);
            // setPageStatus(page.status);
            dispatch(getSummary(page._id, memberIds))
        }
    }, [page])


    useEffect(() => {
        if(summary) {
            // summary.events = summary.events.filter((x)=>{return x.data?.type != 'location' ?? true})
            summary.events = summary.events.map((x)=>{
               if(x.type === 'message' && x.data.reason === 'gps-live-added')x.status = 'started';
               if(x.type === 'message' && x.data.reason === 'gps-live-deleted')x.status = 'ended';
               if(x.type === 'message' && x.data.gps)x.location = {type: "Point", coordinates: [x.data.gps.long, x.data.gps.lat]};
               return x;
            });
            setPageStatus(summary.page?.[0]?.status ?? '');
            setEvents(summary.events);
        }
    }, [summary])


    useEffect(() => {
        let startDate = getFirstEvent().datetime;
        let stopDate = getLastEvent().datetime;

        setStartTime(getLocalDatetimeString(new Date(startDate)));
        setEndTime(getLocalDatetimeString(new Date(stopDate)));


        const imei = getDeviceImei();
        if(imei) {
            let distanceData = {from: startDate || new Date(), to: stopDate || new Date(), imei}
            console.log('distance data', distanceData, getDistance(distanceData))
            dispatch(getDistance(distanceData))
        }

        const startLocationCoordinates = getFirstEvent().location?.coordinates;
        if (startLocationCoordinates) {
            getAddress(startLocationCoordinates[1], startLocationCoordinates[0]).then((address) =>{
                setStartAddress(address)
            });
        }
        const stopLocationCoordinates = getLastEvent().location?.coordinates;
        if (stopLocationCoordinates) {
            getAddress(stopLocationCoordinates[1], stopLocationCoordinates[0]).then((address) =>{
                setEndAddress(address)
            });
        }
    }, [events]);

    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    useEffect(() => {

        socket.connect();

        let pageId = getParameterByName('pageId')
        dispatch(getPage(pageId))
        subscribeToDPSPage(pageId)

        socket.on('user/status', (data)=>{
            setMemberStatus((prevState) => ({
                ...prevState,
                [data.meta_data.user.userId]: data.meta_data.user.status,
            }))
        });

        socket.on('page/status', (data)=>{
            setPageStatus(data.meta_data.page.status)
        });

        socket.on('member/updated', (data)=>{
            console.log('member update', data)
            dispatch(getPage(pageId))
        });

        socket.on('page/event', (data)=>{
            let event = data.meta_data.event;
            if(event.type === 'message' && event.data.reason === 'gps-live-added')event.status = 'started';
            if(event.type === 'message' && event.data.reason === 'gps-live-deleted')event.status = 'ended';
            if(event.type === 'message' && event.data.gps)event.location = {type: "Point", coordinates: [event.data.gps.long, event.data.gps.lat]};
            setEvents((prevState => [
                ...prevState,
                event
            ]))
        });
    }, []);

    const getResponderId = () => {
        return page.members.find(
            member => member?.user?.role === 'responder'
        )?.user.beamId?.IdOne ?? 'N/A';
    }

    const getCadId = () => {
        return page.members.find(
            member => member?.user?.role === 'dispatcher'
        )?.user.beamId?.IdOne ?? 'N/A';
    }

    const getEventIcon = (event) =>{
        switch (event.type){
            case 'event':
                return 'Place-icon';
            case 'regular-call':
                return 'Audio-icon';
            case 'b2t-call':
                return 'B2T-icon';
            case 'beam-assist':
                return 'Incident-icon';
            case 'capture':
                return 'Capture-icon';
            case 'camera-recording':
                return 'Video-icon';
            case 'alert':
                return 'Alert-icon';
            case 'message':
                switch (event.data.type) {
                    case 'gps':
                        return 'iot_blue';
                    case 'location':
                        return 'location';
                    case 'gps-report':
                        return 'IoT File-icon';
                    case 'media':
                        return 'Image-icon';
                    case 'rsvp':
                        return 'RSVP-icon';
                    case 'rsvp-response':
                        return 'RSVP-icon';
                    case 'video-recording':
                        return 'Video-icon';
                    case 'sound-recording':
                        return 'Audio-icon';
                    case 'event':
                        return 'Event-icon';
                }
                break;
        }
    }


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const getTime = (time) => {
        if (time < 10)
            time = '0' + time;
        return time;
    }

    const checkin = (time) => {
        let points = breakTime.filter(bt => bt.time === time);
        if (points.length > 0)
            return points[0]
        return null
    }

    return (
        <Modal title=""
               visible={true}
            // onOk={handleOk}
            // onCancel={handleCancel}
               width='100%'
               className="beam-modal analytics-summary-modal"
               footer={[]}
               destroyOnClose={true}
        >
            {page &&
            <React.Fragment>
                <Row>
                    <Col span={12} className="pr-3">
                        <Row className="d-flex">
                            <Col>
                                <h3 className="mb-1">{page.title}</h3>
                                {/*<h4>Incidents information</h4>*/}
                            </Col>
                            <Col>
                                {/*<Button className="beam-btn icon-btn ml-2"><ReactSVG*/}
                                {/*    src="/images/flag-icon.svg"/></Button>*/}

                                <Button className="beam-btn icon-btn ml-2"><ReactSVG src="/images/flag-icon.svg" className={`${pageStatusMapping[pageStatus]?.icon ?? 'gray'}-icon`} /></Button>

                                <Button className="beam-btn icon-btn ml-2"><ReactSVG
                                    src="/images/iot-icon.svg"/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <ul className="incident-information mt-2">
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Customer</p></Col>
                                            <Col span={12}><p>{page.profile?.name || 'N/A'}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>First name</p></Col>
                                            <Col span={12}><p>{page.profile?.firstName || 'N/A'}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Last name</p></Col>
                                            <Col span={12}><p>{page.profile?.lastName || 'N/A'}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Phone</p></Col>
                                            <Col span={12}><p>{page.profile?.callerPhoneNumber || 'N/A'}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Alternative Phone</p></Col>
                                            <Col span={12}><p>{page.profile?.altphonenumber || 'N/A'}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Incident type</p></Col>
                                            <Col span={12}><p>{page.profile?.requestType || 'on-move'}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>CAD ID</p></Col>
                                            <Col span={12}><p>{getCadId()}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Responder ID</p></Col>
                                            <Col span={12}><p>{getResponderId()}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Distance</p></Col>
                                            <Col span={12}><p>{distance ?? '0'} m</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Dispatch Time</p></Col>
                                            <Col span={12}><p>{getLocalDatetimeString(page.createdAt || '')}</p></Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row>
                                            <Col span={12}><p>Start Time</p></Col>
                                            <Col span={12}><p>{startTime || ''}</p></Col>
                                        </Row>
                                    </li><li>
                                    <Row>
                                        <Col span={12}><p>Stop Time</p></Col>
                                        <Col span={12}><p>{endTime || ''}</p></Col>
                                    </Row>
                                </li>



                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className="pl-3 mt-3">
                        <h4 className="mt-3">Locations</h4>
                        <Row className="locations mt-2">
                            <Col span={24}>
                                <LocationItem address={startAddress} time={startTime} type="green"/>
                                <LocationItem address={endAddress} time={endTime} type="blue"/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={24}>
                                <TextArea autoSize={{minRows: 3, maxRows: 3}} placeholder="Protocals"/>
                            </Col>
                        </Row>
                        <Row className="d-flex px-3">
                            <Col><p>Optional</p></Col>
                            <Col><p>max. 500 character</p></Col>
                        </Row>
                        <Row className="d-flex mt-3">
                            <Col span={8} className="pr-2"><Button className="beam-btn w-full">Survey</Button></Col>
                            <Col span={8} className="px-2"><Button className="beam-btn w-full">NCIC</Button></Col>
                            <Col span={8} className="pl-2"><Button className="beam-btn w-full">NFIRS</Button></Col>
                        </Row>
                        <Row className="d-flex mt-3">
                            <Col span={24}><Button className="beam-btn w-full green-btn cover">End Event</Button></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col><h4 className="mt-3">People On Page</h4></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24} className="">
                        {page.members.map((member, i) =>
                            <PersonItem member={member} status={memberStatus[member.user._id] || 'offline'} avatar="https://first.beam.live/assets/images/unknown.png"/>
                        )}
                        {/*<PersonItem type="blue" avatar="/images/users/user-0.png"/>*/}
                        {/*<PersonItem type="green" avatar="/images/users/user-1.png"/>*/}
                    </Col>
                    {/*<Col span={12} className="pl-3">*/}
                    {/*    <PersonItem type="yellow" avatar="/images/users/user-2.png"/>*/}
                    {/*    <PersonItem type="purple" avatar="/images/users/user-3.png"/>*/}
                    {/*</Col>*/}
                </Row>
                {/*<Row className="mb-3">*/}
                {/*    <Col><h4 className="mt-3">People Recommended</h4></Col>*/}
                {/*</Row>*/}
                {/*<Row className="mt-3">*/}
                {/*    <Col span={12} className="pr-3">*/}
                {/*        <PersonItem type="orange" avatar="/images/users/user-4.png"/>*/}
                {/*        <PersonItem type="blue" avatar="/images/users/user-5.png"/>*/}
                {/*    </Col>*/}
                {/*    <Col span={12} className="pl-3">*/}
                {/*        <PersonItem type="yellow" avatar="/images/users/user-6.png"/>*/}
                {/*        <PersonItem type="purple" avatar="/images/users/user-7.png"/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row className="mt-3">*/}
                {/*    <Col span={12} className="pr-3">*/}
                {/*        <Row>*/}
                {/*            <Col><h4 className="mt-3">Assets On Scene</h4></Col>*/}
                {/*        </Row>*/}
                {/*        <Row className="assets-block d-flex mt-3">*/}
                {/*            <Col><ReactSVG src="/images/tester-icon.svg"/></Col>*/}
                {/*            <Col><ReactSVG src="/images/user-icon.svg"/></Col>*/}
                {/*            <Col><ReactSVG src="/images/tester-icon.svg"/></Col>*/}
                {/*            <Col><ReactSVG src="/images/heart-icon.svg"/></Col>*/}
                {/*            <Col><ReactSVG src="/images/user-icon.svg"/></Col>*/}
                {/*            <Col span={6}><ReactSVG src="/images/heart-icon.svg"/></Col>*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*    <Col span={12} className="pl-3">*/}
                {/*        <Row>*/}
                {/*            <Col><h4 className="mt-3">Assets Recommended</h4></Col>*/}
                {/*        </Row>*/}
                {/*        <Row className="assets-block d-flex mt-3">*/}
                {/*            <Col><ReactSVG src="/images/tester-icon.svg"/></Col>*/}
                {/*            <Col><ReactSVG src="/images/user-icon.svg"/></Col>*/}
                {/*            <Col><ReactSVG src="/images/tester-icon.svg"/></Col>*/}
                {/*            <Col><ReactSVG src="/images/heart-icon.svg"/></Col>*/}
                {/*            <Col><ReactSVG src="/images/user-icon.svg"/></Col>*/}
                {/*            <Col span={6}><ReactSVG src="/images/heart-icon.svg"/></Col>*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*</Row>*/}


                {/*<Row className="timeline mt-4">*/}
                {/*    <Col span={24}>*/}
                {/*        <div className="main-bar">*/}
                {/*            {_.times(48, (i) => {*/}
                {/*                let timePoint = null;*/}
                {/*                let breakPoint = null;*/}
                {/*                let point = checkin(i - 1);*/}
                {/*                if ((i - 1) % 5 === 0)*/}
                {/*                    timePoint = <h6>9:{getTime(i - 1)} am</h6>*/}
                {/*                if (point)*/}
                {/*                    breakPoint = <div className="break-point">*/}
                {/*                        <Button className="beam-btn icon-btn"><img*/}
                {/*                            src={`/images/${point.name}-icon.png`}/></Button>*/}
                {/*                        <h5 className="mt-2">{point.name}</h5>*/}
                {/*                        <p className="mt-1">9:{getTime(i - 1)} am</p>*/}
                {/*                    </div>*/}
                {/*                if (timePoint != null || breakPoint != null)*/}
                {/*                    return (*/}
                {/*                        <div*/}
                {/*                            className={`time-point mark-time ${breakPoint ? point.icon + '-icon break' : ''}`}*/}
                {/*                            key={i}>*/}
                {/*                            {timePoint}*/}
                {/*                            {breakPoint}*/}
                {/*                        </div>*/}
                {/*                    )*/}
                {/*                else*/}
                {/*                    return <div className="time-point" key={i}></div>*/}
                {/*            })}*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row className="custom-table mt-4">
                    <Col span={24}>
                        <Row className="header">
                            <Col span={6}>Event</Col>
                            <Col span={5}>User</Col>
                            <Col span={5}>Time</Col>
                            <Col span={5}>Status</Col>
                            <Col span={3}>Location</Col>
                        </Row>
                        {events && events.map((event, i) =>
                            <Row className="table-row d-flex">
                                <Col span={6} className="event-column">
                                    <div><img src={`/images/${getEventIcon(event)}.png`}/></div>
                                    <span>{event.type == 'event' ? event.eventName.toUpperCase(): event.type == 'message' ?  event.data.type.toUpperCase(): event.type.toUpperCase()}</span>
                                </Col>
                                <Col span={5}>{event.userName ? event.userName: page.members.find((x)=>{return x.user._id == event.userId})?.user?.beamId?.IdOne ?? 'N/A'}</Col>
                                <Col span={5}>{getLocalDatetimeString(event.createdAt)}</Col>
                                <Col span={5}>
                                    {(event.status || (event.type != 'message' && event.type != 'event')) &&
                                        (
                                            <label
                                                className={`${pageStatusMapping[event.status]?.icon ?? 'gray'}-icon`}>{event.status ? event.status: event.data.subtype}</label>
                                        )
                                    }
                                </Col>

                                <Col span={3}>
                                    {event?.location &&
                                        <button type="button" className="ant-btn beam-btn icon-btn" onClick={()=>{window.open("https://maps.google.com/?q=" + event.location.coordinates[1] + "," + event.location.coordinates[0])}}>
                                            <img src={`/images/map.png`}/>
                                        </button>
                                    }

                                    {/*{JSON.stringify(event?.location) || 'N/A'}*/}
                                </Col>
                            </Row>
                        )}

                        {/*<Row className="table-row d-flex">*/}
                        {/*    <Col span={4} className="event-column">*/}
                        {/*        <div><img src="/images/Place-icon.png"/></div>*/}
                        {/*        <span>Place</span>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={4}></Col>*/}
                        {/*    <Col span={3}>9:02 am</Col>*/}
                        {/*    <Col span={3}><label className="green-icon">Open</label></Col>*/}
                        {/*    <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>*/}
                        {/*</Row>*/}
                        {/*<Row className="table-row d-flex">*/}
                        {/*    <Col span={4} className="event-column">*/}
                        {/*        <div><img src="/images/IoT-icon.png"/></div>*/}
                        {/*        <span>IoT</span>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={4}>Call taker ABC</Col>*/}
                        {/*    <Col span={3}>9:02 am</Col>*/}
                        {/*    <Col span={3}><label className="green-icon">Received</label></Col>*/}
                        {/*    <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>*/}
                        {/*</Row>*/}
                        {/*<Row className="table-row d-flex">*/}
                        {/*    <Col span={4} className="event-column">*/}
                        {/*        <div><img src="/images/Capture-icon.png"/></div>*/}
                        {/*        <span>Capture</span>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={4}>Capture</Col>*/}
                        {/*    <Col span={3}>9:02 am</Col>*/}
                        {/*    <Col span={3}><label className="green-icon">Open</label></Col>*/}
                        {/*    <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>*/}
                        {/*</Row>*/}
                        {/*<Row className="table-row d-flex">*/}
                        {/*    <Col span={4} className="event-column">*/}
                        {/*        <div><img src="/images/Incident-icon.png"/></div>*/}
                        {/*        <span>Incident</span>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={4}>Responder ID</Col>*/}
                        {/*    <Col span={3}>9:02 am</Col>*/}
                        {/*    <Col span={3}><label className="green-icon">Open</label></Col>*/}
                        {/*    <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>*/}
                        {/*</Row>*/}

                    </Col>
                </Row>
            </React.Fragment>
            }
        </Modal>
    )
}

export default AnalyticsSummaryModal;
