import {
  GET_PAGE,
  GET_PAGE_SUCCESS,
  GET_SUMMARY,
  GET_SUMMARY_SUCCESS,
  GET_DISTANCE,
  GET_DISTANCE_SUCCESS
} from './actionTypes'


export const getPage = (pageId) => ({
  type: GET_PAGE,
  pageId
})

export const getPageSuccess = (data) => ({
  type: GET_PAGE_SUCCESS,
  payload: data
})


export const getSummary = (pageId, userIds) => ({
  type: GET_SUMMARY,
  pageId,
  userIds
})

export const getSummarySuccess = (data) => ({
  type: GET_SUMMARY_SUCCESS,
  payload: data
})


export const getDistance = (data) => ({
  type: GET_DISTANCE,
  data
})

export const getDistanceSuccess = (data) => ({
  type: GET_DISTANCE_SUCCESS,
  payload: data
})
