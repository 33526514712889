import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { interceptor } from "utils/interceptor";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { AnalyticsSummaryModal } from '../../components/Modals';

const { Header, Content, Footer } = Layout

export default () => {

  const history = useHistory();
  const [isLoggedIn, setLoggedIn] = useState();
	// const { loginSuccessfull } = useSelector((state) => state.auth);
  useEffect(()=>{
    console.log(process.env.REACT_APP_BASE_API_URI);
  },[])
  useEffect(()=>{
    setLoggedIn(!!localStorage.getItem('TOKEN'));
  },[isLoggedIn])


  interceptor();
  return (
    <Layout>
      <Content className="site-layout">
        <AnalyticsSummaryModal />
      </Content>
    </Layout>
  );
}
