import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/rootReducer';
import root from '../sagas/rootSaga';

const configureStore = (initialState) => {
  const sagaMiddleware = createSagaMiddleware();
  const enhancer = compose(
    applyMiddleware(sagaMiddleware)
  );

  const store = createStore(
    rootReducer,
    initialState,
    enhancer
  );

  sagaMiddleware.run(root);

  return store;
};

export default configureStore
