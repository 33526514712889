import { all, call, put, takeLatest, takeEvery} from 'redux-saga/effects'
import { GET_PAGE, GET_SUMMARY, GET_DISTANCE } from 'actions/Page/actionTypes'
import { getPageSuccess, getSummarySuccess, getDistanceSuccess } from 'actions/Page'
import { pushNotification } from 'utils/notifications'
import {getPage, getSummary, getDistance} from "../services/apis/collections/oAuthApis";
import { generateUUID } from 'utils/appUtil';


function* getPageSaga(action) {
  try {
    const response = yield call(getPage, action.pageId)
    console.log(response);
    if (response && response.meta_data) {
      // pushNotification('P', 'success', 'TOP_CENTER', 1000)
      yield put(getPageSuccess(response.meta_data.pages));
    } else {
      pushNotification('Something went wrong', 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* getSummarySaga(action) {
  try {
    const response = yield call(getSummary, {pageId: action.pageId, userIds: action.userIds});
    // console.log('summary here', response);
    if (response && response.meta_data) {
      // pushNotification('P', 'success', 'TOP_CENTER', 1000)
      yield put(getSummarySuccess(response.meta_data.summary));
    } else {
      pushNotification('Something went wrong', 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}


function* getDistanceSaga(action) {
  try {
    console.log('get distance saga');
    const response = yield call(getDistance, action.data);
    // console.log('summary here', response);
    if (response && response.meta_data) {
      yield put(getDistanceSuccess(response.meta_data.distance));
    } else {
      pushNotification('Something went wrong', 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}


function* watchGetRequest() {
  yield takeEvery(GET_PAGE, getPageSaga);
}

function* watchGetSummaryRequest() {
  yield takeEvery(GET_SUMMARY, getSummarySaga);
}

function* watchGetDistanceRequest() {
  yield takeEvery(GET_DISTANCE, getDistanceSaga);
}

export default function* sagas() {
  yield all([
    watchGetRequest(),
    watchGetSummaryRequest(),
    watchGetDistanceRequest()
  ])
}
