import {
  GET_PAGE,
  GET_PAGE_SUCCESS,
  GET_SUMMARY_SUCCESS,
  GET_DISTANCE_SUCCESS,
} from 'actions/Page/actionTypes'
import { createAction } from '@reduxjs/toolkit';

// export const GET_PAGE = createAction('getPage');

const initialState = {
  pageLoading: false,
  page: undefined
}

const getPage = (state, action) => ({
  ...state,
  pageLoading: true
})

const getPageSuccess = (state, action) => {
  return ({
    ...state,
    pageLoading: false,
    page: action.payload
  })
}

const getSummarySuccess = (state, action) => {
  return ({
    ...state,
    pageLoading: false,
    summary: action.payload
  })
}

const getDistanceSuccess = (state, action) => {
  return ({
    ...state,
    distance: parseInt(action.payload)
  })
}

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGE: return getPage(state, action)
    case GET_PAGE_SUCCESS: return getPageSuccess(state, action)
    case GET_SUMMARY_SUCCESS: return getSummarySuccess(state, action)
    case GET_DISTANCE_SUCCESS: return getDistanceSuccess(state, action)
    default: return state
  }
}

export default pageReducer
