import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import pageReducer from './pageReducer'
import searchReducer from './searchReducer'
import messageReducer from './messageReducer'
import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
  routing: routerReducer,
  page: pageReducer,
  search: searchReducer,
  message: messageReducer,
  form: formReducer
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
