  
const initialState = {
	messages: [{
		id: 1,
		name: 'Leonetta Llyod',
		avatar: '',
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id tortor pretium, dictum purus ut, ullamcorper nulla. Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. Fusce ullamcorper lectus et feugiat convallis. Curabitur interdum.',
		type: 'message',
		time: '11:12:48',
        seenBy: [{
            id: 1,
            name: 'Sister'
        },
        {
            id: 2,
            name: 'Leona'
        },
        {
            id: 3,
            name: 'Ekoterina'
        }]
	},
	{
		id: 2,
		name: 'Ekaterina',
		avatar: '',
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id tortor pretium, dictum purus ut, ullamcorper nulla. Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. Fusce ullamcorper lectus et feugiat convallis. Curabitur interdum.',
		type: 'message',
		time: '11:12:48',
        seenBy: [{
            id: 1,
            name: 'Leona'
        },
        {
            id: 2,
            name: 'Ekoterina'
        },
        {
            id: 3,
            name: 'Sister'
        }]
	},{
		id: 3,
		name: 'sister@beam.live',
		avatar: '',
		text: 'has been added to the page by Zsolt',
		type: 'member',
		time: '11:12:48',
        seenBy: [{
            id: 1,
            name: 'Sister'
        },
        {
            id: 2,
            name: 'Leona'
        },
        {
            id: 3,
            name: 'Ekoterina'
        }]
	},{
		id: 4,
		name: 'Sister',
		avatar: '',
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id tortor pretium, dictum purus ut, ullamcorper nulla. Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. Fusce ullamcorper lectus et feugiat convallis. Curabitur interdum.',
		type: 'message',
		time: '11:35:48',
        seenBy: [{
            id: 1,
            name: 'Sister'
        },
        {
            id: 1,
            name: 'Leona'
        },
        {
            id: 1,
            name: 'Ekoterina'
        }]
	},{
		id: 3,
		name: 'Leonetta Lloyd',
		avatar: '',
		text: 'has been added to the page by Zsolt',
		type: 'member',
		time: '11:12:48',
        seenBy: [{
            id: 1,
            name: 'Sister'
        },
        {
            id: 1,
            name: 'Leona'
        },
        {
            id: 1,
            name: 'Ekoterina'
        }]
	}],
}



const messageReducer = (state = initialState, action) => {
	switch (action.type) {
		default: return state
	}
}

export default messageReducer;
  