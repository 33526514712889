export const AUTH_API_ACCESS_TOKEN = 'auth.api.access_token';
export const AUTH_ID_TOKEN = 'auth.id_token';
export const AUTH_API_ACCESS_TOKEN_EXPIRES_AT = 'auth.api.access_token_expires_at';

export const SESSION_EXPIRES_AT = 'session.expires_at';

export const USER_ID = 'user.id'; // represents the user.id pk
export const AUTH0_USER_ID = 'auth0_user.id'; // represents auth0 unique-id for user
export const USER_TYPE = 'user.type';
export const USER_EMAIL = 'user.email';
export const USER_FIRST_NAME = 'user.first_name';
export const USER_LAST_NAME = 'user.last_name';
export const USER_PHONE_NUMBER = 'user.phone_number';
export const USER_EMAIL_VERIFIED = 'user.email_verified';
export const USER_ORIGINATED_FROM = 'user.originated_from';
export const USER_EVENT_STATE = 'user.event_state';

// export const LIVE_URL = 'http://127.0.0.1:5000/v2/'
export const LIVE_URL = 'https://page-dev-api.beam.live/v2/';
// export const DPS_URL = 'http://localhost:11002/api/v1/'
export const DPS_URL = 'https://page-dev-gps.beam.live/api/v1/';
export const DPS_SOCKET = 'https://page-dev-gps.beam.live';
